<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" ><g><rect fill="none" height="24" width="24"/></g><g><g enable-background="new"><path d="M7,12L7,12c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1l0,0c0-0.55-0.45-1-1-1H8C7.45,11,7,11.45,7,12z M12,2 C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconMinus',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
